











import { Vue, Prop, Component } from 'vue-property-decorator';
import { IOrderBumpRelatedProduct } from '@/sparkmembers/views/ProductSetup/components/OrderBumpConfig/types';

@Component({
  components: {
    OrderBumpRelatedItem: () => import('./OrderBumpRelatedItem.vue'),
  },
})
export default class OrderBumpRelatedProducts extends Vue {
  @Prop({ required: true }) target: string;
  @Prop({ required: true }) productList: Array<IOrderBumpRelatedProduct>;
}
